import React from "react";
import logo from "./header_section/logo1.png";
import { Link } from "react-router-dom";
import {
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaEnvelope,
  FaMapMarkerAlt,
} from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="py-12 bg-gradient-to-r from-purple-100 to-blue-100 text-gray-700">
      <div className="container mx-auto px-4">
        {/* Logo Section */}
        <div className="flex justify-center mb-8">
          <img
            src={logo}
            alt="Versai Tech Solutions Logo"
            className="w-40 h-auto"
          />
        </div>

        {/* Main Content */}
        <div className="flex flex-col md:flex-row md:justify-between w-full">
          {/* Left Section - Our Vision */}
          <div className="w-full lg:w-350 md:w-[400px]  mb-8 md:mb-0 text-center md:text-left">
            <h2 className="text-2xl font-bold mb-4">Our Vision</h2>
            <p>
              Versai Tech Solutions is a pioneer in providing comprehensive
              services in website development, app development, and digital
              marketing. We understand the ever-growing demands of today’s
              digital landscape and strive to deliver innovative solutions that
              drive sustained growth for enterprises across various industries.
            </p>
          </div>

          {/* Middle Section - Contact Us */}
          <div className="w-full md:pl-3 md:w-1/3 mb-8 md:mb-0 text-center md:text-left">
            <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
            <div className="flex flex-col items-center md:items-start">
              <div className="flex items-center mb-2 gap-2">
                <ion-icon name="call"></ion-icon>

               <span className="font-bold"> <a
                    href="tel:7597683262"
                    className="text-blue-500  hover:"
                  >Get in touch</a></span>
              </div>
              <div className="flex items-center mb-2">
                <FaEnvelope className="mr-2" />
                <span className="font-bold">
                  
                  <a
                    href="mailto:info@versai.in"
                    className="text-blue-500  hover:"
                  >
                    Mail us
                  </a>
                </span>
              </div>
              <div className="flex items-center mb-2">
                <FaMapMarkerAlt className="mr-2" />
                <span className="font-bold">Address:</span>
              </div>
              <div>
                <p>Ajitgarh,  <br></br> Jhunjhunu, <br></br> <b> Rajasthan</b></p>
                <p className="font-bold">Pincode: 333701</p>
              </div>
            </div>
          </div>

          {/* Right Section - Company Info */}
          <div className="w-full md:w-1/6 mb-8 md:mb-0 text-center md:text-left">
            <h2 className="text-2xl font-bold mb-4">Company</h2>
            <ul>
              <Link to="/About-us" onClick={()=>window.scrollTo({top:0,behavior:'smooth'})}>
                <li className="mb-2 hover:text-gray-900">About Us</li>
              </Link>
             <Link to="/Career" onClick={()=>window.scrollTo({top:0,behavior:'smooth'})}>
              <li className="mb-2  hover:text-gray-900">
              
                
                  Career
                
                </li>
                </Link>
              <Link to="/Contact-us" onClick={()=>window.scrollTo({top:0,behavior:'smooth'})}>
                <li className="mb-2  hover:text-gray-900">Contact Us</li>
              </Link>
            </ul>
          </div>

          {/* Right Section - Support Info */}
          <div className="w-full md:w-1/6 text-center md:text-left">
            <h2 className="text-2xl font-bold mb-4">Support</h2>
            <ul>
              <Link to="/Privacy-policy">
                <li className="mb-2 hover:text-gray-900">Privacy Policy</li>
              </Link>
              <Link to="/Term-and-condition">
                <li className="mb-2 hover:text-gray-900">Terms and Conditions</li>
              </Link>
              <Link to="/Refund">
                <li className="mb-2 hover:text-gray-900">Cancellation and Refund</li>
              </Link>
              <Link to="/Faqs">
                <li className="mb-2 hover:text-gray-900">FAQ</li>
              </Link>
            </ul>
          </div>
        </div>

        {/* Social Media Links */}
        <div className="flex justify-center mt-8">
          <a href="https://x.com/Versai_Tech" className="mx-2 text-2xl text-gray-500 hover:text-gray-700">
            <FaTwitter />
          </a>
          <a href="https://www.instagram.com/versai.in/" className="mx-2 text-2xl text-gray-500 hover:text-gray-700">
            <FaInstagram />
          </a>
          <a href="https://www.linkedin.com/in/versai-tech-solution-a09913331/" className="mx-2 text-2xl text-gray-500 hover:text-gray-700">
            <FaLinkedin />
          </a>
        </div>

        {/* Footer Bottom - Copyright */}
        <div className="text-center mt-8">
          <p className="text-gray-500">
            &copy; 2024{" "}
            <span className="text-orange-500">Versai Tech Solutions</span> All
            Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
