import React from "react";
import Hero from './header_section/Hero/Hero'
import Mainsection from "./Main";
function Header() {
  
  return (
    <div>
      <Hero  />
      <Mainsection />
    </div>
  );
}

export default Header;
