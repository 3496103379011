import React from 'react'
// import CustomDevelopmentFeatures from './main_section/CustomDevelopmentFeatures'
import Working from './main_section/Working'
function Main() {
  
  return (
    <div>
      {/* <CustomDevelopmentFeatures /> */}
      <Working />
    </div>
  );
}

export default Main